import * as React from 'react'

import {
    selectConfig,
    setStatus,
    updateNowTime,
} from '../core/coreSlice'
import { enableNetworkAssets, setSelectedMapbox } from '../mapbox/mapboxSlice'
import { changeHorizon } from '../horizonSlider/horizonSilderSlice'
import { getMarks } from '../../helpers/SliderMarksHelper'
import { loadMapDataByViewMode } from '../core/mapData/mapDataSlice'
import HorizonSlider from '../horizonSlider/horizonSlider'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getAllViewModeFromNames } from '../../helpers/ContainerHelper'
import MapboxComponent from '../mapbox/mapboxComponent'
import { QmMapContainerWrapper, QmMapToolbarContainer } from './qualityManagerDialogStyles'
import { StyledDivider } from '../../components'
import ClockQM from '../clock/clockQualityManager'

import { useEffect, useState } from 'react'

export const QualityManagerMap: React.FC<IQualityManagerDialogMapProps> = ({ options }) => {
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)

    const [mapDimensions, setMapDimensions] = useState<{ height: number; width: number }>({
        width: window.innerWidth - 65,
        height: window.innerHeight - 300,
    })
    const dispatch = useAppDispatch()

    const horizonStep: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const clockInterval: number = Number.parseInt(_moduleConfig['clock-interval']) / 60

    const marks = getMarks(horizonStep, numberOfHorizon)
    const allViewModes = getAllViewModeFromNames(options.visualisation.viewModes, _moduleConfig.view_mode)

    useEffect(() => {
        setStatus('loading')
        dispatch(enableNetworkAssets({ networks: 'all', mapHorizon: 'default' }))
        dispatch(updateNowTime())

        const handleResize = () => {
            setMapDimensions({
                width: window.innerWidth - 65,
                height: window.innerHeight - 300,
            })
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
            setStatus('loading')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMapDataByEpoch = (epoch: number) => {
        dispatch(changeHorizon(0))
        dispatch(
            loadMapDataByViewMode({
                epoch: epoch,
                allViewMode: allViewModes,
                viewModeId: allViewModes[0].id,
                mapId: 1,
                qm: true,
            })
        )
    }

    const handleChangeHorizonAndDate = (horizon: number) => {
        dispatch(changeHorizon(horizon))
    }

    const handleChangeViewMode = (epoch: number, viewModeId: number, mapId: number) => {
        dispatch(
            loadMapDataByViewMode({ epoch, allViewMode: allViewModes, viewModeId: viewModeId, mapId: mapId, qm: true })
        )
    }

    const handleMapboxSelected = (id: number) => {
        dispatch(setSelectedMapbox(id))
    }

    return (
        <QmMapContainerWrapper>
            <QmMapToolbarContainer>
                <ClockQM
                    minutesStep={clockInterval}
                    longTimeStep={{ number: 1, unit: 'hours' }}
                    qmHorizon={horizonStep * 6}
                    updateQmTime={getMapDataByEpoch}
                />
                <StyledDivider orientation='vertical' />
                <HorizonSlider
                    isDisabled={false}
                    horizonValue={0}
                    marks={marks}
                    horizonStep={horizonStep}
                    changeHorizonAndDate={handleChangeHorizonAndDate}
                />
            </QmMapToolbarContainer>
            <MapboxComponent
                networkLayers={['detectors']}
                actionLayers={[]}
                incidentLayers={[]}
                mapId={1}
                viewport={mapDimensions}
                setSelected={handleMapboxSelected}
                containerName={'QM'}
                showLegend={true}
                changeViewMode={handleChangeViewMode}
                isBaseline={false}
            />
        </QmMapContainerWrapper>
    )
}
