import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getModuleConfig(): Promise<AxiosResponse<IModuleConfig>> {
    return getRequestAPI('/config/module_config.json')
}

export function getNowTime(): Promise<AxiosResponse<INowTime>> {
    return getRequestAPI('/api_clock/now_time', { cache: false })
}

export function getTimeZone(): Promise<AxiosResponse<ITimeZone>> {
    return getRequestAPI('/api_dm/time_zone')
}

export function getTilesCatalog(mapHorizon: string): Promise<AxiosResponse<ITileCollection>> {
    return getRequestAPI(`/tiles/${mapHorizon}/catalog`)
}

export function getTilesInfo(tileId: string, mapHorizon: string): Promise<AxiosResponse<ITileInfo>> {
    return getRequestAPI(`/tiles/${mapHorizon}/${tileId}`)
}

export function getVariableTypesWS(): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/variables?type=statistics`)
}

export function getStrategy(): Promise<AxiosResponse<IModuleConfig>> {
    return getRequestAPI('/config/db_default/gkstrategy.json')
}

export function getPolicies(): Promise<AxiosResponse<IModuleConfig>> {
    return getRequestAPI('/config/db_default/gkpolicy.json')
}

export function getPtLinesJson(): Promise<AxiosResponse<any>> {
    return getRequestAPI('/config/db_default/gksubpath_ptLines.json')
}

export function getAvailableNames(object: string): Promise<AxiosResponse<string[]>> {
    return getRequestAPI(`/api_dm/available_names?object=${object}`, { cache: false })
}
