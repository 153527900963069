import * as React from 'react'
import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton, Switch, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
import { StationDialogTooltip } from './stationDialogTooltip'
import { TooltipCircle, TooltipCircleBackground } from '../mapbox/mapboxStyles'
import { stationDialogChartOptions } from './stationDialogChartOption'
import {
    CardContentStyled,
    CardHeaderStyled,
    CardStationStyled,
    CardTitleWrapper,
    ShowOtherPrediction,
} from './stationDialogStyles'
import { getStationDialogLegend } from './stationDialogLegend'
import { useSelector } from 'react-redux'
import {
    selectStationAnalyticalPredictionData,
    selectStationPatternData,
    selectStationPredictionData,
    selectStationRealData,
    selectStationReliabilityData, selectStationSimulatedPredictionData,
} from './store/stationSelectedSlice'
import {
    preparePatternMaxDataToChart,
    preparePatternMinDataToChart,
    prepareRealDataToChart,
    preparePredictionDataToShowOnChart,
    preparePredictionDataToHideChart,
    getTypePredictionSource,
} from './stationDialogHelper'
import { getSeriesBothPredictionData, getSeriesData } from './stationDialogChartSeries'
import { RootState } from '../../app/store'
import { selectConfig, selectTimeZone } from '../core/coreSlice'
import { NO_DATA } from '../../theme'
import { useTranslation } from 'react-i18next'
import { getUnitTypeFromUnit } from '../../helpers/unitsHelper'
import { useEffect, useState } from 'react'
import { stationDialogChartBothPredictionOptions } from './stationDialogChartBothPredictionOption'
import { StationDialogTooltipBothPrediction } from './stationDialogTooltipBothPrediction'
import { getStationDialogLegendBothPrediction } from './stationDialogLegendBothPrediction'

const GRAPH_ASPECT_RATIO = 1.37
const MARGIN_BOTTOM_GRAPH = 20

export const StationDialog: React.FC<ChartsDialogProps> = ({
    valueColor = NO_DATA,
    reliabilityColor = NO_DATA,
    typeFeature = '',
    stationName = 'Unknown',
    epoch,
    onClose,
    mapId,
    height,
    width,
    viewMode,
}) => {
    const { t } = useTranslation()
    const _timeZone: string = useSelector(selectTimeZone)
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const realData: realDataType[] = useSelector((state: RootState) => selectStationRealData(state, mapId))
    const predictionData = useSelector((state: RootState) => selectStationPredictionData(state, mapId))
    const analyticalPredictionData = useSelector((state: RootState) => selectStationAnalyticalPredictionData(state, mapId))
    const simulatedPredictionData = useSelector((state: RootState) => selectStationSimulatedPredictionData(state, mapId))
    const patternData = useSelector((state: RootState) => selectStationPatternData(state, mapId))

    const [showOtherPrediction, setShowOtherPrediction] = useState<boolean>(false)
    const [typePredictionSource, setTypePredictionSource] = useState<string>('')

    const cleanerInterval: number = Number.parseInt(_moduleConfig['clock-interval']) / 60
    const unitType = _moduleConfig.units === 'imperial' ? getUnitTypeFromUnit(viewMode.units.main) : undefined

    const reliabilityData: dataReliabilityType[] = useSelector((state: RootState) =>
        selectStationReliabilityData(state, mapId)
    )

    useEffect(() => {
        switch (typeFeature) {
            case 'GKDetector':
            {
                setTypePredictionSource(getTypePredictionSource('stations', viewMode))
                return
            }
            case 'GKSection':
            {
                setTypePredictionSource(getTypePredictionSource('sections', viewMode))
                return
            }
            default:
            {
                setTypePredictionSource('')
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeFeature])

    const realDataSeries: lineDataChart[] = prepareRealDataToChart(
        realData,
        epoch,
        cleanerInterval,
        _timeZone,
        unitType
    )
    const predictionDataSeries: lineDataChart[] = preparePredictionDataToShowOnChart(
        predictionData,
        realData,
        epoch,
        cleanerInterval,
        _timeZone,
        unitType
    )
    const predictionDataHideSeries: lineDataChart[] = preparePredictionDataToHideChart(
        predictionData,
        realData,
        epoch,
        cleanerInterval,
        _timeZone,
        unitType
    )
    let otherPredictionDataSeries: lineDataChart[] = []
    let otherPredictionDataHideSeries: lineDataChart[] = []
    if (_moduleConfig.showOtherPredictionOnGraph) {
        const data = typePredictionSource === 'analytical' ? simulatedPredictionData : analyticalPredictionData
        otherPredictionDataSeries = preparePredictionDataToShowOnChart(
            data,
            realData,
            epoch,
            cleanerInterval,
            _timeZone,
            unitType
        )
        otherPredictionDataHideSeries= preparePredictionDataToHideChart(
            data,
            realData,
            epoch,
            cleanerInterval,
            _timeZone,
            unitType
        )
    }
    const patternMinDataSeries: lineDataChart[] = preparePatternMinDataToChart(
        patternData,
        epoch,
        cleanerInterval,
        _timeZone,
        unitType
    )
    const patternMaxDataSeries: lineDataChart[] = preparePatternMaxDataToChart(
        patternData,
        epoch,
        cleanerInterval,
        _timeZone,
        unitType
    )

    const toolTipOnePrediction = ({ dataPointIndex }: any) => {
        return StationDialogTooltip({
            timeZone: _timeZone,
            hour: realDataSeries[dataPointIndex] ? realDataSeries[dataPointIndex].x : null,
            real: realDataSeries[dataPointIndex] ? realDataSeries[dataPointIndex].y : null,
            dataReliability: realData[dataPointIndex] ? realData[dataPointIndex].reliability : null,
            patterMin: patternMinDataSeries[dataPointIndex] ? patternMinDataSeries[dataPointIndex].y : null,
            patterMax: patternMaxDataSeries[dataPointIndex] ? patternMaxDataSeries[dataPointIndex].y : null,
            sensorReliability: reliabilityData[dataPointIndex] ? reliabilityData[dataPointIndex].value : null,
            prediction:
                predictionDataHideSeries[dataPointIndex] && predictionDataHideSeries[dataPointIndex].x !== epoch
                    ? predictionDataHideSeries[dataPointIndex].y
                    : null,
            viewMode,
        })
    }

    const toolTipBothPrediction = ({ dataPointIndex }: any) => {
        return StationDialogTooltipBothPrediction({
            timeZone: _timeZone,
            hour: realDataSeries[dataPointIndex] ? realDataSeries[dataPointIndex].x : null,
            real: realDataSeries[dataPointIndex] ? realDataSeries[dataPointIndex].y : null,
            dataReliability: realData[dataPointIndex] ? realData[dataPointIndex].reliability : null,
            patterMin: patternMinDataSeries[dataPointIndex] ? patternMinDataSeries[dataPointIndex].y : null,
            patterMax: patternMaxDataSeries[dataPointIndex] ? patternMaxDataSeries[dataPointIndex].y : null,
            sensorReliability: reliabilityData[dataPointIndex] ? reliabilityData[dataPointIndex].value : null,
            prediction:
                predictionDataHideSeries[dataPointIndex] && predictionDataHideSeries[dataPointIndex].x !== epoch
                    ? predictionDataHideSeries[dataPointIndex].y
                    : null,
            otherPrediction:
                otherPredictionDataHideSeries[dataPointIndex] && otherPredictionDataHideSeries[dataPointIndex].x !== epoch
                    ? otherPredictionDataHideSeries[dataPointIndex].y
                    : null,
            currentPrediction: typePredictionSource,
            viewMode,
        })
    }
    const toolTip = showOtherPrediction ? toolTipBothPrediction : toolTipOnePrediction

    const series = showOtherPrediction ? getSeriesBothPredictionData(
        realDataSeries,
        patternMaxDataSeries,
        patternMinDataSeries,
        predictionDataSeries,
        predictionDataHideSeries,
        otherPredictionDataSeries,
        otherPredictionDataHideSeries,
        typePredictionSource
    ) : getSeriesData(
        realDataSeries,
        patternMaxDataSeries,
        patternMinDataSeries,
        predictionDataSeries,
        predictionDataHideSeries
    )

    const handleClose = () => {
        onClose()
    }

    const handleCHangePrediction = () => {
        setShowOtherPrediction(!showOtherPrediction)
    }

    const heightGraphInitial = ((width * 0.65) / GRAPH_ASPECT_RATIO)

    const widthGraph =
        heightGraphInitial > height + MARGIN_BOTTOM_GRAPH
            ?  _moduleConfig.showOtherPredictionOnGraph ?
                GRAPH_ASPECT_RATIO * (height - MARGIN_BOTTOM_GRAPH - 8) * 0.75
                : GRAPH_ASPECT_RATIO * (height - MARGIN_BOTTOM_GRAPH - 8)
            : _moduleConfig.showOtherPredictionOnGraph ? width * 0.55 : width * 0.60

    const addToHeight = _moduleConfig.showOtherPredictionOnGraph ? 48 : 0
    const heightInitialEstimate = (widthGraph / GRAPH_ASPECT_RATIO) + addToHeight
    const heightGraph = heightInitialEstimate > 770 ? heightInitialEstimate - 70 : heightInitialEstimate

    const cardTitle = (
        <CardTitleWrapper>
            <TooltipCircle
                style={{
                    background: valueColor,
                }}
            />
            <TooltipCircleBackground
                style={{
                    backgroundColor: reliabilityColor,
                }}
            />
            <Typography variant='h1'>{stationName}</Typography>
        </CardTitleWrapper>
    )

    const optionsChart = showOtherPrediction ?
        stationDialogChartBothPredictionOptions(epoch, _timeZone, toolTip, viewMode.name, getStationDialogLegendBothPrediction(t, typePredictionSource))
        : stationDialogChartOptions(epoch, _timeZone, toolTip, viewMode.name, getStationDialogLegend(t)
    )

    return (
        <CardStationStyled
            width={`${widthGraph}px`}
            height={`${heightGraph}px`}
            marginbottom={`${MARGIN_BOTTOM_GRAPH}px`}>
            <CardHeaderStyled
                action={
                    <IconButton aria-label='settings' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={cardTitle}
            />
            {_moduleConfig.showOtherPredictionOnGraph && (
                <ShowOtherPrediction
                    control={
                        <Switch
                            color='primary'
                            checked={showOtherPrediction}
                            onChange={handleCHangePrediction}
                        />
                    }
                    label={typePredictionSource !== '' ?
                        typePredictionSource === 'analytical' ?
                            t('stationDialog.showSimulatedPrediction')
                            : t('stationDialog.showAnalyticalPrediction')
                        : ''}
                    labelPlacement={'start'}
                />
            )}
            <CardContentStyled showotherprediction={_moduleConfig.showOtherPredictionOnGraph ? 0 : 1}>
                {series && (
                    <Chart
                        options={optionsChart}
                        series={series}
                        type={'line'}
                    />
                )}
            </CardContentStyled>
        </CardStationStyled>
    )
}
