import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
    getModuleConfig,
    getNowTime,
    getPtLinesJson,
    getTimeZone,
    getVariableTypesWS,
    getPolicies,
    getStrategy,
    getAvailableNames,
} from '../../services/core/coreService'
import {
    initialSetup,
    storeConfig,
    storeTimeZone,
    storeNowTime,
    storeSystemServices,
    setRealDataUpdates,
    updateNowTime,
    storeVariableTypesWS,
    getPtLines,
    storePtLines,
    storeStrategy,
    storePolicies,
    selectConfig,
    storeEventNames,
    storeActionNames,
    storeResponsePlanNames,
    storeEvaluationNames,
    getNames,
} from './coreSlice'
import { getSystemServices } from '../../services/systemStatus/systemStatusService'

function* init(): Generator<unknown, any, unknown> {
    yield all([
        yield call(fetchNowTime),
        yield call(fetchModuleConfig),
        yield call(fetchVariableTypes),
        yield call(fetchTimeZone),
        yield call(fetchSystemServices),
        yield call(fetchStrategy),
        yield call(fetchPolicies),
    ])
}

function* fetchModuleConfig(): Generator<unknown, any, IModuleConfig> {
    const moduleConfig: IModuleConfig = yield call(getModuleConfig)
    yield put(storeConfig(moduleConfig))
}

function* fetchVariableTypes(): Generator<unknown, any, IVariablesTypeWS> {
    const variablesTypeWS: IVariablesTypeWS = yield call(getVariableTypesWS)
    yield put(storeVariableTypesWS(variablesTypeWS))
}

function* fetchNowTime(): Generator<unknown, any, INowTime> {
    const nowTime: INowTime = yield call(getNowTime)
    yield put(storeNowTime(nowTime.time))
}

function* fetchTimeZone(): Generator<unknown, any, ITimeZone> {
    const tz: ITimeZone = yield call(getTimeZone)
    yield put(storeTimeZone(tz.name))
}

function* fetchSystemServices(): Generator<unknown, any, any> {
    const servicestxt = yield call(getSystemServices)
    const services = servicestxt.split(/\r?\n/)
    yield put(storeSystemServices(services))
}

function* fetchPtLinesServices(): Generator<unknown, any, any> {
    const ptLines = yield call(getPtLinesJson)
    yield put(storePtLines(ptLines))
}

function* fetchAvailableNames(action: any): Generator<unknown, any, any> {
    const names: any = yield call(getAvailableNames, action.payload)
    if (action.payload === 'event') {
        yield put(storeEventNames(names))
    } else if (action.payload === 'action') {
        yield put(storeActionNames(names))
    } else if (action.payload === 'response') {
        yield put(storeResponsePlanNames(names))
    } else if (action.payload === 'evaluation') {
        yield put(storeEvaluationNames(names))
    }
}

function* fetchStrategy(): Generator<unknown, any, any> {
    const strategies = yield call(getStrategy)
    const sortedStrategies = strategies.sort((a: any, b: any) => {
        if (a.eid < b.eid) return -1
        else if (a.eid > b.eid) return 1
        return 0
    })
    yield put(storeStrategy(sortedStrategies))
}

function* fetchPolicies(): Generator<unknown, any, any> {
    const policies = yield call(getPolicies)
    yield put(storePolicies(policies))
}

export function* updateGetNowTime() {
    const config: IModuleConfig = yield select(selectConfig)
    const currentModule = config.modules.find(module => module.url === window.location.pathname.split('?')[0])
    if (currentModule?.usesWebsocket !== false) {
        const nowTime: INowTime = yield call(getNowTime)
        yield put(storeNowTime(nowTime.time))
    } else {
        console.warn(`now_time not used for module ${currentModule?.name}`)
    }
}

function* coreSaga() {
    // @ts-ignore
    yield all([
        takeLatest(initialSetup, init),
        takeLatest(setRealDataUpdates, updateGetNowTime),
        takeLatest(updateNowTime, fetchNowTime),
        takeLatest(getPtLines, fetchPtLinesServices),
        takeLatest(getNames, fetchAvailableNames),
    ])
}

export default coreSaga
