import React, { useEffect, useMemo, useState } from 'react'
import {
    LogoutRounded as LogOut,
    ArrowBackIosRounded as ArrowBack,
    ArrowForwardIosRounded as ArrowForward,
    PlaylistAddCheckRounded,
    Settings as SettingsIcon,
    FormatListBulleted,
    Checklist,
    Summarize,
} from '@mui/icons-material'

import { Grid, Tooltip } from '@mui/material'
import {
    IconWrapper,
    OpenSidebarToggle,
    SidebarActions,
    SidebarItem,
    TextWrapper,
    SidebarContainer,
    IconIncidentWrapper,
    LogOutSidebarButton,
} from './sidebarStyles'
import { QualityManagerDialog } from '../qualityManagerDialog/qualityManagerDialog'
import { SystemStatusDialog } from '../systemStatus/SystemStatusDialog'
import { useTranslation } from 'react-i18next'
import { TasksDialog } from '../../containers/manageContainer/components/tasks/TaskDialog'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectDailyReportFlag,
    selectDetectedIncidents,
    selectListEventsFlag,
    toggleDailyReport,
    toggleListEvents,
} from '../mapbox/mapboxSlice'
import { useAppSelector } from '../../app/hooks'
import { ListEventsInfoModal } from '../listEventsModal/listEventsInfoModal'
import { selectViewOpen } from '../viewSettings/viewSettingsSlice'
import { loadEventsLog } from '../listEventsModal/store/listEventsSlice'
import { selectConfig, selectEpoch, selectNowTime, selectTimeZone } from '../core/coreSlice'
import { DateTime } from 'luxon'
import { subtractTimeToDate } from '../../helpers/DateTimeHelper'
import { parseJwt } from '../auth/auth'
import { selectAccessToken } from '../auth/authSlice'
import { ConfirmLogOutDialog } from './confirmLogOutDialog/confirmLogOutDialog'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { ListEventsDetectedInfoModal } from '../listEventsModal/listEventsDetectedInfoModal'
import { DailyReportInfoModal } from '../dailyReportModal/dailyReportInfoModal'
import { loadDataRange } from '../../containers/dashboardContainer/DashboardContainerSlice'
import { restoreInitialReportInfo } from '../dailyReportModal/store/dailyReportSlice'

const Sidebar: React.FC<ISidebarProps> = ({ toggleSidebar, open, epoch, options, containerName }: ISidebarProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _listEventsFlag: boolean = useAppSelector(selectListEventsFlag)
    const _dailyReportFlag: boolean = useAppSelector(selectDailyReportFlag)
    const _timeZone: string = useSelector(selectTimeZone)
    const _epoch: number = useAppSelector(selectEpoch)
    const _nowTime: number = useAppSelector(selectNowTime)
    const _accessToken: string = useAppSelector(selectAccessToken)
    const _detectedIncidents: any = useAppSelector(selectDetectedIncidents)
    const [systemStatusDialogOpen, setSystemStatusDialogOpen] = useState<boolean>(false)
    const [qmDialogOpen, setQmDialogOpen] = useState<boolean>(false)
    const [tasksDialogOpen, setTasksDialogOpen] = useState<boolean>(false)
    const [logOutDialogOpen, setLogOutDialogOpen] = useState<boolean>(false)
    const [eventsDetectedDialogOpen, setEventsDetectedDialogOpen] = useState<boolean>(false)

    const _viewsOpen: boolean = useAppSelector(selectViewOpen)

    const username: string | undefined = useMemo(() => {
        if (_accessToken) {
            const jwtContent: IJwtContent = parseJwt(_accessToken)
            let user = jwtContent.username
            if (user.startsWith('aimsun_')) {
                user = user.replace('aimsun_', '')
            }
            return user
        }
        return undefined
    }, [_accessToken])

    const useLogin: boolean = useMemo(() => {
        if (_moduleConfig.auth) {
            if (_moduleConfig.auth['use-login'] === 'false') {
                return false
            }
            return Boolean(_moduleConfig.auth['use-login'])
        }
        return true
    }, [_moduleConfig])

    useEffect(() => {
        if (_listEventsFlag) {
            const dateTo = DateTime.fromMillis(_epoch, { zone: _timeZone })
            const dateFrom = subtractTimeToDate(dateTo, 'days', 14).startOf('day')

            dispatch(loadEventsLog({ from: dateFrom.toMillis(), to: _nowTime, type: 'idm' }))
            setEventsDetectedDialogOpen(false)
            dispatch(toggleDailyReport(false))
            dispatch(restoreInitialReportInfo())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_listEventsFlag])

    useEffect(() => {
        if (_viewsOpen) {
            dispatch(toggleDailyReport(false))
            dispatch(restoreInitialReportInfo())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_viewsOpen])

    useEffect(() => {
        if (_dailyReportFlag) {
            dispatch(toggleListEvents(false))
            setEventsDetectedDialogOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dailyReportFlag])

    useEffect(() => {
        if (_detectedIncidents) {
            if (_detectedIncidents.features.length === 0) {
                setEventsDetectedDialogOpen(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_detectedIncidents])

    const toggleQmDialog = (open: boolean): void => {
        setQmDialogOpen(!open)
        dispatch(toggleDailyReport(false))
        setEventsDetectedDialogOpen(false)
    }

    const toggleSystemStatusDialog = (open: boolean): void => {
        setSystemStatusDialogOpen(!open)
        dispatch(toggleListEvents(false))
        dispatch(toggleListEvents(!_listEventsFlag))
        dispatch(toggleDailyReport(false))
        setEventsDetectedDialogOpen(false)
    }

    const toggleListEventsDialog = (): void => {
        dispatch(toggleListEvents(!_listEventsFlag))
    }

    const toggleDailyReportDialog = (): void => {
        if (_dailyReportFlag) {
            dispatch(restoreInitialReportInfo())
        } else {
            dispatch(loadDataRange())
        }
        dispatch(toggleDailyReport(!_dailyReportFlag))
    }

    const toggleTaskDialog = (open: boolean): void => {
        setTasksDialogOpen(!open)
    }

    const confirmLogOut = () => {
        setLogOutDialogOpen(true)
    }

    const logOut = () => {
        setLogOutDialogOpen(false)
        localStorage.clear()
        const url = new URL('https://login.aimsun.com')
        url.searchParams.append('redirect', window.location.href.split('?', 1)[0])
        window.location.href = url.href
    }

    const toggleEventsDetectedDialog = (open: boolean): void => {
        setEventsDetectedDialogOpen(open)
        dispatch(toggleListEvents(false))
        dispatch(toggleDailyReport(false))
    }

    return (
        <>
            <SidebarContainer container direction='column' justifyContent='flex-start' alignItems='center' open={open}>
                <SidebarActions>
                    {useLogin && (
                        <Tooltip title={username} placement='right'>
                            <LogOutSidebarButton onClick={() => confirmLogOut()}>
                                <IconWrapper>
                                    <LogOut fontSize='large' />
                                </IconWrapper>
                                {open && <TextWrapper>{t('sideBar.signOut')}</TextWrapper>}
                            </LogOutSidebarButton>
                        </Tooltip>
                    )}

                    <OpenSidebarToggle onClick={() => toggleSidebar(open)}>
                        <IconWrapper>
                            {open ? <ArrowBack fontSize='large' /> : <ArrowForward fontSize='large' />}
                        </IconWrapper>
                        {open && <TextWrapper>{t('sideBar.showLess')}</TextWrapper>}
                    </OpenSidebarToggle>
                </SidebarActions>
                {options?.quality_manager && options.quality_manager.active && (
                    <SidebarItem
                        container
                        onClick={() => toggleQmDialog(qmDialogOpen)}
                        key='quality manager'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <PlaylistAddCheckRounded key='QM' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('qualityManager.qualityManager')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {options?.list_events && options.list_events.active && (
                    <SidebarItem
                        container
                        onClick={toggleListEventsDialog}
                        key='log events'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <Checklist key='list events' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('listEvents.eventLog')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {options?.daily_report && options.daily_report.active && (
                    <SidebarItem
                        container
                        onClick={toggleDailyReportDialog}
                        key='daily Report'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <Summarize key='DailyReport' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('dailyReport.dailyReport')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {options?.system_status && options.system_status.active && (
                    <SidebarItem
                        container
                        onClick={() => toggleSystemStatusDialog(systemStatusDialogOpen)}
                        key='system status'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <SettingsIcon key='SystemStatus' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('systemStatus.systemStatus')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {options?.tasks && (
                    <SidebarItem
                        container
                        onClick={() => toggleTaskDialog(tasksDialogOpen)}
                        key='system status'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <FormatListBulleted key='Tasks' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('tasks.tasks')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {_detectedIncidents.features.length > 0 &&
                    (containerName === 'monitor' || containerName === 'analyze') && (
                        <SidebarItem
                            container
                            onClick={() => toggleEventsDetectedDialog(true)}
                            key='incidents detected'
                            direction='row'
                            alignItems='center'
                            wrap='nowrap'
                            spacing={1}>
                            <Grid item>
                                <IconIncidentWrapper>
                                    <CustomIcon
                                        style={{ width: '30px', height: '30px' }}
                                        name={'detectedEventWhite'}
                                        key='detected_incident'
                                    />
                                </IconIncidentWrapper>
                            </Grid>
                            {open && (
                                <Grid item>
                                    <TextWrapper>{t('listEvents.eventsDetected')}</TextWrapper>
                                </Grid>
                            )}
                        </SidebarItem>
                    )}
            </SidebarContainer>
            {qmDialogOpen && (
                <QualityManagerDialog
                    open={qmDialogOpen}
                    epoch={epoch}
                    onClose={toggleQmDialog}
                    options={options.quality_manager}
                />
            )}
            {systemStatusDialogOpen && (
                <SystemStatusDialog open={systemStatusDialogOpen} onClose={toggleSystemStatusDialog} epoch={epoch} />
            )}
            {tasksDialogOpen && <TasksDialog open={tasksDialogOpen} onClose={toggleTaskDialog} epoch={epoch} />}
            {logOutDialogOpen && (
                <ConfirmLogOutDialog
                    open={logOutDialogOpen}
                    onClose={() => setLogOutDialogOpen(false)}
                    onConfirm={logOut}
                />
            )}
            {_listEventsFlag && !_viewsOpen && <ListEventsInfoModal />}
            {_dailyReportFlag && !_viewsOpen && <DailyReportInfoModal />}
            {eventsDetectedDialogOpen && !_viewsOpen && (
                <ListEventsDetectedInfoModal
                    detectedIncidents={_detectedIncidents}
                    toggleEventsDetectedDialog={toggleEventsDetectedDialog}
                />
            )}
        </>
    )
}

export default Sidebar
